<template>
  <div>
    <div v-if="updated">
      <el-alert title="Success" type="success" :closable="false" show-icon></el-alert>
    </div>
    <div v-else>
      <form id="payment-form">
        <div class="form-row">
          <!-- <label for="card-element">
          Credit or debit card
          </label>-->
          <div id="card-element" v-loading="loading"></div>
          <div class="is-size-6-7 has-text-grey" id="card-errors" role="alert"></div>
        </div>
        <br />
      </form>
      <el-button type="primary" @click="addCard">{{ hasStripe ? 'Update Card' : 'Add Card' }}</el-button>
      <el-button type="text" @click="$emit('cancel')">Cancel</el-button>
    </div>
  </div>
</template>

<script>
import { createStripeCustomer, updateStripeCustomer } from '@/api/stripe.service'
import AuthHelper from '@/helpers/auth-helper'

const testPk = 'pk_test_qLgITfEDE94HZFE9XEK7E4VI'
const livePk = 'pk_live_5ugJ61oJzRWNQiNCdh1aV3kc'

const elementsStyle = {
  base: {
    // fontSize: '16px',
    // color: '#32325d',
    fontFamily: 'Avenir',
  },
}

// Add an instance of the card Element into the `card-element` <div>.
export default {
  name: 'StripeCard',
  data() {
    return {
      card: null,
      loading: false,
      user: null,
      updated: false,
      stripeUrl: 'https://yvjf08l2nj.execute-api.us-east-1.amazonaws.com',
      isProd: this.$store.state.isProd,
      stripeLive: this.$store.state.stripeEnv !== 'test',
      stripeInstance: null,
    }
  },
  computed: {
    stripePk() {
      return this.stripeLive ? livePk : testPk
    },
    hasStripe() {
      return this.user && this.user[this.stripeCustomAttr]
    },
    stripeCardCreateUrl() {
      if (this.stripeLive) {
        return `${this.stripeUrl}/prod/card`
      }
      return `${this.stripeUrl}/dev/card`
    },
    stripeCardUpdateUrl() {
      if (this.stripeLive) {
        return `${this.stripeUrl}/prod/card/update`
      }
      return `${this.stripeUrl}/dev/card/update`
    },
    stripeCustomAttr() {
      if (this.stripeLive) {
        return 'custom:stripeid'
      }
      return 'custom:stripeiddev'
    },
  },
  async created() {
    if (this.$store.state.user) {
      const currentUser = await this.$Amplify.Auth.currentUserInfo()
      this.user = {
        username: this.$store.state.user.username,
        ...currentUser.attributes,
      }
    }
  },
  mounted() {
    this.stripeInstance = window.Stripe(this.stripePk)
    const elements = this.stripeInstance.elements()
    this.card = elements.create('card', { style: elementsStyle })
    this.card.mount('#card-element')
    this.card.addEventListener('change', ({ error }) => {
      const displayError = document.getElementById('card-errors')
      if (error) {
        displayError.textContent = error.message
      } else {
        displayError.textContent = ''
      }
    })
  },
  methods: {
    async addCard() {
      this.loading = true
      const errorElement = document.getElementById('card-errors')
      if (errorElement.innerHTML) {
        this.loading = false
        return
      }
      try {
        const { token, stripeError } = await this.stripeInstance.createToken(this.card)
        if (stripeError) {
          throw new Error(stripeError)
        }
        // const session = await Auth.currentSession()
        // const headers = {
        //   Authorization: session.idToken.jwtToken,
        // }
        // const currentUser = await this.$Amplify.Auth.currentUserInfo()
        if (this.hasStripe) {
          // update the card instead of create a user
          const updateResponse = await updateStripeCustomer({ token: token.id })
          // const updateResponse = await axios.post(
          //   this.stripeCardUpdateUrl,
          //   {
          //     token: token.id,
          //     customer: stripeId,
          //   },
          //   { headers },
          // )
          const newAttributes = await this.$Amplify.Auth.updateUserAttributes(this.$store.state.user, {
            [this.stripeCustomAttr]: updateResponse.data.id,
          })
          await AuthHelper.refreshCurrentSession()
          this.$emit('cardUpdate', newAttributes)
        } else {
          const createResponse = await createStripeCustomer({ token: token.id })
          // const createResponse = await axios.post(
          //   this.stripeCardCreateUrl,
          //   {
          //     token: token.id,
          //   },
          //   { headers },
          // )
          const newAttributes = await this.$Amplify.Auth.updateUserAttributes(this.$store.state.user, {
            [this.stripeCustomAttr]: createResponse.data.id,
          })
          await AuthHelper.refreshCurrentSession()
          this.$emit('cardUpdate', newAttributes)
        }
        this.loading = false
        this.updated = true
      } catch (err) {
        console.log({ err })
        this.loading = false
        errorElement.textContent = JSON.stringify(err)
      }
    },
  },
  beforeDestroy() {
    this.card.destroy()
  },
}
</script>

<style lang="scss" scoped>
/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.form-row {
  margin-top: 8px;
}
</style>
