<template>
  <div>
    <div>
      <div v-if="false" class="columns is-multiline is-centered">
        <div
          class="column is-one-third-desktop is-half-tablet"
          v-for="preview in previews"
          :key="preview.value"
        >
          <el-card
            class="has-border-radius-16 has-cursor-pointer"
            shadow="hover"
            style="border: none;"
            :body-style="{ padding: '0' }"
          >
            <video
              v-if="preview.type === PREVIEW_TYPE.VIDEO"
              preload="true"
              playsinline="true"
              autoplay="true"
              loop="true"
              muted="muted"
              controls
              :src="preview.value"
              class="has-border-radius-16"
            ></video>
            <el-image
              v-else
              :src="preview.value"
              alt="Preview of the product"
              fit="scale-down"
              class="has-border-radius-16"
            ></el-image>
          </el-card>
        </div>
      </div>
    </div>
    <div v-if="true">
      <!-- If just one image or video -->
      <div v-if="singlePreview">
        <video
          class="has-light-shadow"
          v-if="singlePreview.type === PREVIEW_TYPE.VIDEO"
          preload="true"
          playsinline="true"
          autoplay="true"
          loop="true"
          muted="muted"
          controls
          :src="singlePreview.value"
        ></video>
        <img
          class="has-diffused-shadow"
          v-if="singlePreview.type === PREVIEW_TYPE.IMAGE"
          :src="singlePreview.value"
          alt="Preview of the product"
        />
        <div
          v-if="singlePreview.description"
          class="is-size-6-7 has-text-grey-dark"
        >{{ singlePreview.description }}</div>
      </div>
      <div v-else>
        <el-carousel indicator-position="outside" height="450px" :autoplay="true" arrow="always">
          <el-carousel-item v-for="preview in previews" :key="preview.value">
            <video
              v-if="preview.type === PREVIEW_TYPE.VIDEO"
              preload="true"
              playsinline="true"
              autoplay="true"
              loop="true"
              muted="muted"
              controls
              style="height: 400px;"
              :src="preview.value"
            ></video>
            <el-image
              v-if="preview.type === PREVIEW_TYPE.IMAGE"
              :src="preview.value"
              fit="contain"
              style="width: 100%; height: 400px"
            ></el-image>
            <!-- <img v-if="preview.type === PREVIEW_TYPE.IMAGE" :src="preview.value" :alt="service.title" /> -->
            <div
              v-if="preview.description"
              class="is-size-6-7 has-text-grey-dark"
            >{{ preview.description }}</div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { PREVIEW_TYPE } from '@/graph-constants'

export default {
  name: 'ServicePreview',
  props: { previews: Array },
  data() {
    return {
      PREVIEW_TYPE,
      singlePreview: null,
    }
  },
  created() {
    if (this.previews.length === 1) {
      this.singlePreview = this.previews[0]
    }
  },
}
</script>