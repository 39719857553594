export const STATUS = Object.freeze({
  CREATED: 'CREATED',
  RECEIVED: 'RECEIVED_BY_VENDOR',
  PROCESSING: 'VENDOR_PROCESSING',
  FULFILLED: 'FULFILLED',
  NOT_RECEIVED: 'NOT_RECEIVED_BY_VENDOR',
  REJECTED: 'REJECTED_BY_VENDOR',
  FAILED: 'FAILED_BY_VENDOR',
})

export const PREVIEW_TYPE = Object.freeze({
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  LINK: 'LINK',
})

export const INPUT_TYPE = Object.freeze({
  TEXT: 'TEXT',
  COLOR: 'COLOR',
  FILE: 'FILE',
  SELECT: 'SELECT',
})

export const OUTPUT_TYPE = Object.freeze({
  TEXT: 'TEXT',
  LINK: 'LINK',
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  AUDIO: 'AUDIO',
})

export const API_TYPE = Object.freeze({
  RESPONSE: 'RESPONSE',
  POLL: 'POLL',
  PUSH: 'PUSH',
})
