import {
  getMetatagAttribute
} from '../helpers/utils'

export default {
  methods: {
    setMeta(tags) {
      Object.keys(tags).forEach((tag) => {
        const attribute = getMetatagAttribute(tag)
        const oldNodes = [...document.querySelectorAll(`[${attribute}="${tag}"]`)]
        oldNodes.forEach(n => n && n.remove())
        const newTag = document.createElement('meta')
        newTag.setAttribute(attribute, tag)
        newTag.setAttribute('content', tags[tag])
        document.head.appendChild(newTag)
      })
    },
  }
}
