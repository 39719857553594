<template>
  <div class="tip ghost has-padding-bottom">
    <p class="is-size-6-7">
      You've used {{ creditsUsed }} of {{ subscription.plan.allowance }}
      {{ subscription.plan.allowance === 1 ? 'credit' : 'credits' }} of
      <a
        class="has-text-weight-bold"
        @click="$emit('viewPlan')"
      >your plan</a>
    </p>
    <el-progress :percentage="usagePercentage" :status="usageStatus" :format="progressFormat"></el-progress>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionUsageChart',
  props: ['subscription', 'requisitions'],
  methods: {
    progressFormat(percentage) {
      return percentage >= 100 ? 'Full' : `${percentage}%`
    },
  },
  computed: {
    creditsUsed() {
      return this.requisitions.reduce((acc, req) => acc + (req.credits || 1), 0)
    },
    usagePercentage() {
      if (this.subscription.plan.isUnlimited) {
        return 0
      }
      return Math.round((this.creditsUsed / this.subscription.plan.allowance) * 100)
    },
    usageStatus() {
      if (this.usagePercentage >= 100) {
        return 'exception'
      } else if (this.usagePercentage >= 80) {
        return 'warning'
      }
      return undefined
    },
  },
}
</script>
