<template>
  <div v-loading="subscriptionActionLoading" class="columns is-multiline">
    <div
      v-for="plan in plans"
      :key="plan.id"
      class="has-margin-vertical service-plan__container column"
    >
      <el-card class="has-border-radius-16 has-text-centered service-plan__card" shadow="hover">
        <h3 class="is-size-4 has-text-centered has-text-weight-bold">{{plan.name }}</h3>
        <div class="price">
          <span class="price__dollar">$</span>
          <span class="price__amount">{{ (plan.amount / 100) }}</span>
          <span class="price__frequency">/{{ plan.interval }}</span>
        </div>

        <div class="has-small-margin-top">
          <div v-if="isSubscribedToPlan(plan)">
            <el-button type="primary" disabled>Subscribed</el-button>
            <a :href="getPlanSubscriptionUrl(plan)">
              <el-button class="has-margin-left" type="text">Manage Subscription</el-button>
            </a>
          </div>
          <div v-else>
            <el-button
              v-if="isSubscribedToAPlan"
              type="primary"
              @click="updateSubscription(plan)"
            >Switch To This Plan</el-button>
            <el-button
              v-else
              type="primary"
              round
              class="subscribe has-text-weight-semibold is-size-6"
              @click="createSubscription(plan)"
            >Subscribe for {{ plan.amountFormatted }}</el-button>
            <!-- <el-button v-if="isSubscribedToAPlan(plan)"
            type="primary" disabled @click="subscribe(plan)">Subscribed</el-button>-->
          </div>
        </div>

        <div>
          <p class="is-size-6-7 has-text-grey has-small-margin-top">Cancel anytime with one click.</p>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {
  createStripeSubscription,
  // updateStripeSubscription,
} from '@/api/stripe.service'

export default {
  name: 'Plans',
  props: ['plans', 'subscriptions'],
  data() {
    return {
      subscription: this.subscriptions.find((sub) => this.plans.map((p) => p.id) === sub.plan.id),
      subscriptionActionLoading: false,
      user: this.$store.state.user,
    }
  },
  computed: {
    isSubscribedToAPlan() {
      const subscriptionPlanIds = this.subscriptions.map((sub) => sub.plan.id)
      return this.plans.reduce((acc, plan) => subscriptionPlanIds.includes(plan.id) || acc, false)
    },
  },
  methods: {
    async createSubscription(plan) {
      if (!this.user) {
        this.$emit('requesting-auth')
        return
      }
      if (plan.amount !== 0) {
        const stripeAttr = this.$store.state.stripeEnv === 'live' ? 'custom:stripeid' : 'custom:stripeiddev'
        const currentUser = await this.$Amplify.Auth.currentUserInfo()
        if (!currentUser || !currentUser.attributes[stripeAttr]) {
          this.$emit('request-payment-details')
          return
        }
      }
      this.subscriptionActionLoading = true
      try {
        const res = await createStripeSubscription({ planId: plan.id })
        this.$message({
          message: 'Subscribed!',
        })
        this.$emit('subscription-created', plan)
      } catch (e) {
        console.log({ e })
        let errorMessage = 'Error subscribing to plan. Please try again or contact our support team'
        if (e.response && e.response.data && e.response.data.errorMessage) {
          errorMessage = e.response.data.errorMessage
        }
        this.$message({
          message: errorMessage,
          type: 'error',
          duration: 6000,
        })
      } finally {
        this.subscriptionActionLoading = false
      }
    },
    // async updateSubscription(plan) {
    //   console.log('Updating subscription to', plan)
    //   try {
    //       const res = await updateStripeSubscription(this.subscription.id, {
    //     })
    //     this.$message({
    //       message: 'Subscribed!',
    //     })
    //     console.log({ res })
    //   } catch (e) {
    //     console.log(e)
    //     this.$message({
    //       message: 'Error subscribing to plan. Please try again or contact our support team',
    //     })
    //   }
    //   this.$emit('subscribed', plan)
    // },
    isSubscribedToPlan(plan) {
      return this.subscriptions.map((sub) => sub.plan.id).includes(plan.id)
    },
    getPlanSubscriptionUrl(plan) {
      const matchingSubscription = this.subscriptions.find((sub) => sub.plan.id === plan.id)
      if (matchingSubscription) {
        return `/subscription/${matchingSubscription.id}`
      }
      return null
    },
  },
}
</script>

<style lang="scss">
.service-plan__card {
  // max-width: 300px;
  display: inline-block;
  .subscribe {
    border: none;
    background-color: white;
    transition: all 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
}

.service-plan__container:nth-child(3n + 2) .service-plan__card {
  background-color: #ebf0ff;
  .subscribe {
    color: #193497;
  }
}
.service-plan__container:nth-child(3n + 1) .service-plan__card {
  background-color: #ebfffd;
  .subscribe {
    color: #1c5e57;
  }
}
.service-plan__container:nth-child(3n) .service-plan__card {
  background-color: #f9ebff;
  .subscribe {
    color: #4b1e70;
  }
}
.price {
  .price__dollar {
    position: relative;
    font-size: 1.5em;
    color: rgba(0, 0, 0, 0.8);
    vertical-align: top;
    top: 0.35em;
  }
  .price__amount {
    font-weight: 600;
    font-size: 3em;
    letter-spacing: -0.06em;
  }
  .price__frequency {
    position: relative;
    bottom: 1em;
    vertical-align: bottom;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.7);
  }
}
</style>

<style lang="scss" scoped>
</style>
