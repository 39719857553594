<template>
  <div>
    <div class="columns is-multiline">
      <div
        class="column is-half-widescreen is-half-tablet has-margin-top"
        v-for="service in displayedServices"
        :key="service.name"
      >
        <a :href="service.link ? service.link : `/service/${service.slug}`">
          <el-card class="has-border-radius-16 has-cursor-pointer" shadow="hover" style="border: none">
            <product-card
              v-bind:name="service.name"
              v-bind:description="service.description"
              v-bind:vendor="service.vendor"
              v-bind:thumbnail="service.thumbnail"
              v-bind:videoThumbnail="service.videoThumbnail"
              v-bind:cost="service.cost"
              v-bind:tags="service.tags"
            ></product-card>
          </el-card>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue'

export default {
  name: 'RelatedServices',
  components: {
    ProductCard,
  },
  props: {
    tags: Array,
    excluded: Array,
  },
  computed: {
    displayedServices() {
      return this.$store.state.featuredServices
        .filter((service) => service.id && !this.excluded.includes(service.id))
        .filter((service) => {
          return service.tags.reduce((overlap, tag) => {
            return overlap || this.tags.includes(tag)
          }, false)
        })
        .slice(0, 2)
    },
  },
}
</script>
