<template>
  <div ref="service">
    <loader-hummingbird :delay="1000" v-if="loading"></loader-hummingbird>
    <div v-else>
      <div v-if="serviceNotFound" class="has-text-centered">
        <section class="section">
          <h4 class="is-size-4">This service could not be found</h4>
        </section>
      </div>
      <div v-else-if="serviceArchived" class="has-text-centered">
        <section class="section">
          <h4 class="is-size-4">This service has been archived</h4>
        </section>
      </div>
      <div v-else-if="service" class="container narrow-container has-large-padding-top">
        <section class="section has-small-padding-top has-no-padding-bottom">
          <h1
            class="is-size-2-3 has-text-weight-semibold has-font-alternate"
            style="line-height: 1.2em;"
          >{{ service.title }}</h1>
          <div class="tip primary" v-if="subscription && subscription.plan.isUnlimited">
            <p class="is-size-6-7">
              You're subscribed to an unlimited plan. Order away!
              <el-button type="text" size="medium" v-scroll-to="'#order'">View</el-button>
            </p>
          </div>
          <div v-if="subscription && !subscription.plan.isUnlimited">
            <subscription-usage-chart
              :subscription="subscription"
              :requisitions="purchaseHistory || []"
              v-scroll-to="'#order'"
            ></subscription-usage-chart>
          </div>
          <div v-else>
            <p>
              <span class="is-size-4 has-text-dark">{{ serviceAmountString }}</span>
              <span class="has-small-margin-left">
                <el-button
                  type="text"
                  size="medium"
                  v-scroll-to="'#order'"
                  v-if="!plansLoading && plans && plans.length && !subscription"
                >or view monthly plans available</el-button>
              </span>
            </p>
          </div>
        </section>
        <section class="section has-small-padding-bottom has-no-padding-top">
          <div class="has-text-left has-margin-top">
            <p class="is-size-6" v-html="service.description"></p>
          </div>
        </section>
        <section class="section has-small-padding-vertical">
          <div class="container narrower-container has-text-centered">
            <service-preview :previews="service.previews"></service-preview>
          </div>
        </section>

        <section class="has-small-padding-vertical" id="order">
          <el-card class="has-border-radius-16" shadow="always" :body-style="{ padding: '30px' }">
            <h2 class="is-size-4-5 has-text-grey has-text-centered has-text-weight-bold">ORDER FORM</h2>
            <div class="divider"></div>
            <service-input-form
              class="has-padding-top"
              :service="service"
              @update="onInputUpdate"
              @requestAuth="requestAuth"
            ></service-input-form>
            <div class="has-padding-top has-small-padding-bottom">
              <el-button
                :loading="purchasing"
                class="has-margin-top"
                @click="purchase"
                type="primary"
              >
                {{
                purchaseString
                }}
              </el-button>
              <el-button
                class="has-margin-top"
                @click="showDeveloperInfo = !showDeveloperInfo"
                type="text"
              >Use Our API</el-button>
              <div>
                <div v-show="showDeveloperInfo">
                  <br />
                  <p class="has-margin-bottom is-size-6-7">
                    Check out
                    <a
                      target="_blank"
                      href="https://docs.floom.app/buyers/#new-requisition"
                    >the API docs</a>
                    for complete details.
                  </p>
                  <div class="has-position-relative">
                    <el-button
                      style="position:absolute; bottom:18px; right: 18px"
                      @click="copyDeveloperInput"
                    >Copy</el-button>
                    <pre><code>{{ JSON.stringify(developerInput, null, 2) }}</code></pre>
                  </div>
                </div>
                <el-button
                  size="mini"
                  type="text"
                  v-if="showDeveloperInfo"
                  @click="showDeveloperInfo = false"
                >Hide developer info</el-button>
              </div>
            </div>
          </el-card>
        </section>
        <section class="section has-padding-vertical" v-if="plans && plans.length">
          <h2 class="is-size-4">Plans</h2>
          <service-plans
            v-loading="subscriptionLoading"
            @request-payment-details="requestingPaymentDetails = true"
            @requesting-auth="requestAuth"
            @subscription-created="onSubscriptionCreated"
            :plans="plans"
            :subscriptions="subscription ? [subscription] : []"
          ></service-plans>
        </section>
        <section class="section" v-if="serviceTags.length">
          <h2 class="is-size-4">Other Products You Mights Like</h2>
          <related-services :tags="serviceTags" :excluded="[service.id]"></related-services>
        </section>
      </div>
    </div>
    <el-dialog
      title="Please enter payment details"
      :visible.sync="requestingPaymentDetails"
      :width="`${paymentDialogWidth}px`"
    >
      <div class="has-padding-left has-padding-right">
        <stripe-card v-on:cardUpdate="handleCardUpdate" @cancel="requestingPaymentDetails = false"></stripe-card>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="requestingPaymentDetails = false">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog center top="5vh" :visible.sync="requestingAuth" :width="`${authDialogWidth}px`">
      <div class="has-text-centered">
        <full-auth page="sign-up"></full-auth>
      </div>
    </el-dialog>
    <el-dialog
      title="You're out of credits on your plan"
      :visible.sync="showOutOfCreditsWarning"
      :width="`${paymentDialogWidth}px`"
    >
      <div class="has-padding-left has-padding-right">
        <subscription-usage-chart
          v-if="subscription"
          :subscription="subscription"
          :requisitions="purchaseHistory || []"
          v-scroll-to="'#order'"
        ></subscription-usage-chart>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="text"
          @click="
            forceNonSubscriptionOrder = true
            showOutOfCreditsWarning = false
            purchase()
          "
        >Order for {{ serviceAmountString }}</el-button>
        <el-button type="primary" @click="showOutOfCreditsWarning = false">Upgrade Your Plan</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify'
import axios from 'axios'
import { ascending } from 'd3'
import { isUuidv4, generateUuid } from '@/helpers/utils'
import { createRequisition } from '@/graphql/mutations'
import { INPUT_TYPE, PREVIEW_TYPE } from '@/graph-constants'
import { publicUrl } from '@/api/storage'
import { getServiceEndpoint, getServiceIdEndpoint } from '@/api/services'
import ServicePreview from '@/components/Service/ServicePreview.vue'
import ServiceInputForm from '@/components/Service/ServiceInputForm.vue'
import RelatedServices from '@/components/Service/RelatedServices.vue'
import StripeCard from '@/components/StripeCard.vue'
import FilePicker from '@/components/FilePicker.vue'
import LoaderHummingbird from '@/components/LoaderHummingbird.vue'
import FullAuth from '@/components/Auth/FullAuth.vue'
import ServicePlans from '@/components/Service/ServicePlans.vue'
import SubscriptionUsageChart from '@/components/SubscriptionUsageChart.vue'
import Plan from '@/models/Plan'
import Subscription from '@/models/Subscription'
import { getStripePlan, getStripeSubscriptions } from '@/api/stripe.service'
import metaMixin from '@/mixins/metaMixin'
import { reqsByOwner, listSubs, getService } from '@/api/graphql/ServiceHelpers'

export default {
  name: 'Service',
  components: {
    ServicePreview,
    ServiceInputForm,
    RelatedServices,
    StripeCard,
    FilePicker,
    FullAuth,
    LoaderHummingbird,
    ServicePlans,
    SubscriptionUsageChart,
  },
  mixins: [metaMixin],
  data() {
    return {
      INPUT_TYPE,
      PREVIEW_TYPE,
      activeTab: 'order',
      service: null,
      serviceArchived: false,
      userInput: null,
      plans: null,
      subscription: null,
      purchaseHistory: null,
      purchasing: false,
      requestingPaymentDetails: false,
      requestingAuth: false,
      authDialogWidth: 400,
      paymentDialogWidth: 400,
      user: null,
      showDeveloperInfo: false,
      developerInput: null,
      isOwnService: false,
      serviceNotFound: false,
      loading: true,
      plansLoading: true,
      subscriptionLoading: false,
      showOutOfCreditsWarning: false,
      forceNonSubscriptionOrder: false,
      serviceTags: ['popular'],
    }
  },
  async created() {
    const { id: slugOrId } = this.$route.params
    let id
    const isSlug = !isUuidv4(slugOrId)
    if (isSlug) {
      const idFetch = await axios.post(getServiceIdEndpoint, {
        slug: slugOrId,
      })
      id = idFetch.data
    } else {
      id = slugOrId
    }
    if (id) {
      if (this.$store.state.user) {
        const res = await API.graphql(graphqlOperation(getService, { id }))
        this.service = res.data.getService
      } else {
        const res = await axios.post(getServiceEndpoint, { id })
        this.service = res.data
      }
    }
    if (!this.service) {
      this.loading = false
      this.serviceNotFound = true
      return
    }
    // FB pixel tracking
    window.fbq('track', 'ViewContent', {
      value: this.service.amount,
      currency: 'USD',
      content_ids: this.service.id,
      content_type: 'service',
    })
    if (this.service.seoTitle) {
      document.title = this.service.seoTitle
    } else if (this.service.title) {
      document.title = `Floom | ${this.service.title}`
    }
    if (this.service.archived) {
      this.loading = false
      this.serviceArchived = true
      return
    }
    this.developerInput = {
      serviceId: this.service.id,
      input: (this.service.input || []).reduce((acc, input) => {
        acc[input.key] = input.placeholder || input.defaultValue || (input.options && input.options[0].value) || ''
        return acc
      }, {}),
    }
    if (this.$store.state.user) {
      const currentUser = await this.$Amplify.Auth.currentUserInfo()
      this.user = {
        username: this.$store.state.user.username,
        ...currentUser.attributes,
      }
      this.isOwnService = this.user.username === this.service.owner
      this.loading = false
      this.$Progress.start()
      this.$Progress.set(60)
      if (this.service.plans) {
        this.plans = await this.fetchPlans()
        this.$Progress.set(70)
        this.subscription = await this.fetchSubscription()
        this.$Progress.set(80)
        if (this.subscription && !this.subscription.plan.isUnlimited) {
          this.purchaseHistory = await this.fetchServicePurchases()
        }
        this.$Progress.finish()
        this.setMetaTags()
        document.dispatchEvent(new Event('x-app-rendered'))
      }
    } else {
      this.loading = false
      this.setMetaTags()
      this.setPlansAsync()
    }
  },
  mounted() {
    const screenWidth = this.$refs.service.clientWidth
    this.paymentDialogWidth = Math.max(400, screenWidth / 2)
    this.authDialogWidth = Math.min(600, screenWidth)
  },
  computed: {
    primaryPreviewsByType() {
      const previews = {}
      if (this.service && this.service.previews && this.service.previews.length) {
        Object.keys(PREVIEW_TYPE).forEach((type) => {
          const match = this.service.previews.find((p) => p.type === type)
          if (match) {
            previews[type] = match
          }
        })
      }
      return previews
    },
    subscriptionPurchases() {
      if (!this.subscription || !(this.purchaseHistory && this.purchaseHistory.length)) {
        return []
      }
      return (this.purchaseHistory || []).filter((p) => p.subscription && p.subscription.id === this.subscription.id)
    },
    subscriptionPeriodPurchases() {
      return (this.subscriptionPurchases || []).filter((p) => {
        const d = +new Date(p.createdAt)
        return d >= +this.subscription.currentPeriodStart && d < +this.subscription.currentPeriodEnd
      })
    },
    subscriptionCreditsDepleted() {
      if (!this.subscription || this.subscription.plan.isUnlimited) {
        return false
      }
      const creditsUsed = this.subscriptionPeriodPurchases.reduce((acc, req) => acc + (req.credits || 1), 0)
      return creditsUsed >= this.subscription.plan.allowance
    },
    modalWidth() {
      if (this.$refs.service) {
        const screenWidth = this.$refs.service.clientWidth
        return Math.max(400, screenWidth / 1)
      }
      return 400
    },
    serviceAmountString() {
      if (this.service && typeof this.service.amount === 'number') {
        const { amount } = this.service
        if (amount === 0) {
          return 'Free'
        } else if (Math.floor(amount / 100) === amount / 100) {
          return `$${(amount / 100).toFixed(0)}`
        }
        return `$${(amount / 100).toFixed(2)}`
      }
      return ''
    },
    purchaseString() {
      if (this.isOwnService) {
        return 'Test'
      }
      if (this.subscription) {
        return 'Order'
      }
      if (this.service && typeof this.service.amount === 'number') {
        const { amount } = this.service
        if (amount === 0) {
          return 'Order for Free'
        } else if (Math.floor(amount / 100) === amount / 100) {
          return 'Purchase'
          // return `Purchase for $${(amount / 100).toFixed(0)}`
        }
        return 'Purchase'
        // return `Purchase for $${(amount / 100).toFixed(2)}`
      }
      return 'Purchase'
    },
  },
  methods: {
    onInputUpdate(userInput) {
      this.userInput = userInput
    },
    setMetaTags() {
      // remove all meta tags
      const meta = {}
      const metaTitle = this.service.seoTitle || this.service.title
      meta.title = metaTitle
      meta['og:title'] = metaTitle

      const metaDescription = this.service.seoDescription || this.service.description
      meta.description = metaDescription
      meta['og:description'] = metaDescription

      if (this.service.seoImage) {
        meta['og:image'] = this.service.seoImage
      } else if (this.primaryPreviewsByType[PREVIEW_TYPE.IMAGE]) {
        meta['og:image'] = this.primaryPreviewsByType[PREVIEW_TYPE.IMAGE].value
      }

      if (this.primaryPreviewsByType[PREVIEW_TYPE.VIDEO]) {
        meta['og:video'] = this.primaryPreviewsByType[PREVIEW_TYPE.VIDEO].value
      }
      this.setMeta(meta)
    },
    async onSubscriptionCreated() {
      this.subscriptionLoading = true
      this.subscription = await this.fetchSubscription()
      this.subscriptionLoading = false
    },
    async setPlansAsync() {
      this.plans = await this.fetchPlans()
      this.plansLoading = false
      document.dispatchEvent(new Event('x-app-rendered'))
    },
    async fetchPlans() {
      const stripePlansRes = await Promise.all(this.service.plans.items.map((p) => getStripePlan(p.id)))
      const stripePlans = stripePlansRes.map((p) => p.data)
      const plans = this.service.plans.items
        .map((plan) => {
          const stripePlan = stripePlans.find((p) => p.id === plan.stripeId)
          if (stripePlan) {
            return new Plan(plan, stripePlan)
          }
          return null
        })
        .filter((d) => d !== null && d.active)
        .sort((a, b) => ascending(a.amount, b.amount))
      return plans
    },
    async fetchSubscription() {
      if (!this.user) {
        return null
      }
      const subscriptionRes = await API.graphql(
        graphqlOperation(listSubs, {
          limit: 1000,
          filter: {
            owner: { eq: this.$store.state.user.username },
          },
        })
      )
      if (subscriptionRes.data.listSubscriptions.items.length) {
        const { data: stripeSubscriptions } = await getStripeSubscriptions()
        const subscriptions = subscriptionRes.data.listSubscriptions.items
          .filter((sub) => sub.plan.service.id === this.service.id)
          .map((sub) => {
            const stripeSub = stripeSubscriptions.data.find((s) => s.id === sub.stripeId)
            if (stripeSub) {
              return new Subscription(sub, stripeSub)
            }
            return null
          })
          .filter((d) => d !== null)
        if (subscriptions && subscriptions.length) {
          return subscriptions[0]
        }
        return null
      }
      return null
    },
    async fetchServicePurchases() {
      if (!this.user) {
        return []
      }
      let createdAt
      if (this.subscription) {
        createdAt = {
          between: [
            this.subscription.currentPeriodStart.toISOString(),
            this.subscription.currentPeriodEnd.toISOString(),
          ],
        }
      }
      const reqsRes = await API.graphql(
        graphqlOperation(reqsByOwner, {
          owner: this.user.username,
          // get purchases for current billing period
          createdAt,
          filter: {
            vendor: { eq: this.service.owner },
          },
          limit: 1000,
        })
      )
      return reqsRes.data.reqsByOwner.items.filter((r) => r.service.id === this.service.id) || []
    },
    async copyDeveloperInput() {
      try {
        await this.$copyText(JSON.stringify(this.developerInput, null, 2))
        this.$message({
          message: 'Copied to clipboard',
          type: 'success',
        })
      } catch (e) {
        this.$message({
          message: 'Could not copy to clipboard',
          type: 'error',
        })
      }
    },
    async handleCardUpdate() {
      if (this.$store.state.user) {
        const currentUser = await this.$Amplify.Auth.currentUserInfo()
        this.user = {
          username: this.$store.state.user.username,
          ...currentUser.attributes,
        }
        this.requestingPaymentDetails = false
      }
    },
    requestAuth() {
      this.requestingAuth = true
      this.$router.replace({
        path: this.$route.path,
        query: { afterauth: JSON.parse(JSON.stringify(this.$route.fullPath)) },
      })
    },
    formInputToServiceInput(formInput) {
      const serviceInput = {
        key: formInput.key,
        label: formInput.label,
        type: formInput.type,
      }
      if (formInput.type === INPUT_TYPE.SELECT && formInput.selectMultiple) {
        serviceInput.value = JSON.stringify(this.userInput[formInput.key])
      } else {
        serviceInput.value = this.userInput[formInput.key] || null
      }
      return serviceInput
    },
    allRequiredInputsGiven() {
      const formInputs = this.service.input
      const emptyFormInputs = formInputs.filter(this.isFormInputEmpty)
      for (let i = 0; i < emptyFormInputs.length; i += 1) {
        const formInput = emptyFormInputs[i]
        if (formInput.required && formInput.defaultValue === null) {
          return false
        }
      }
      return true
    },
    getValueForFormInput(formInput) {
      return this.userInput[formInput.key]
    },
    isFormInputEmpty(formInput) {
      return this.getValueForFormInput(formInput) === ''
    },
    async purchase() {
      this.purchasing = true
      if (!this.user) {
        this.requestAuth()
        this.purchasing = false
        return
      }
      if (this.service.amount !== 0 && !this.isOwnService) {
        const stripeAttr = this.$store.state.stripeEnv === 'live' ? 'custom:stripeid' : 'custom:stripeiddev'
        if (!this.user || !this.user[stripeAttr]) {
          this.requestingPaymentDetails = true
          this.purchasing = false
          return
        }
      }
      if (this.subscriptionCreditsDepleted && !this.forceNonSubscriptionOrder) {
        this.showOutOfCreditsWarning = true
        this.purchasing = false
        return
      }
      const requisitionInput = {
        requisitionServiceId: this.service.id,
        id: generateUuid(),
        status: [
          {
            value: 'CREATED',
            date: +new Date(),
          },
        ],
      }

      if (!this.allRequiredInputsGiven()) {
        console.log('Please enter all required inputs')
        return
      }
      const nonEmptyInputs = this.service.input.filter((formInput) => !this.isFormInputEmpty(formInput))
      if (nonEmptyInputs.length > 0) {
        requisitionInput.input = nonEmptyInputs.map(this.formInputToServiceInput)
      }
      if (this.$store.state.stripeEnv === 'test' && this.$store.state.username === 'admin') {
        requisitionInput.test = true // use stripe test keys and data
      }
      try {
        const res = await API.graphql(graphqlOperation(createRequisition, { input: requisitionInput }))
        // FB pixel tracking
        window.fbq('track', 'Purchase', {
          value: this.service.amount,
          currency: 'USD',
          contents: [
            {
              id: this.service.id,
              quantity: 1,
            },
          ],
          content_ids: this.service.id,
          content_type: 'service',
        })
        this.purchasing = false
        this.$router.push(`/order/${res.data.createRequisition.id}`)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.preview {
  video {
    max-height: 450px;
  }
}
.has-negative-margin-top {
  margin-top: -1em;
}
@media (max-width: 767px) {
  /* <== You can change this break point as per your  needs */
  .columns-reversed-mobile {
    flex-direction: column-reverse;
    display: flex;
  }
}
</style>
