<template>
<div>
  <!-- <img v-if="file" :src="photoUrl" style="max-width: 100%"> -->
  <el-upload
    :auto-upload="false" :accept="accept"
    action=""
    :multiple="false"
    :on-change="chooseFile"
    :file-list="fileList"
  >
    <el-button size="small" type="primary" ref="chooseButton">Click to Upload</el-button>
  </el-upload>
  <el-progress :percentage="progressPercentage" :status="progressStatus"
     v-if="progressPercentage > 0"
  ></el-progress>
  <div class="error" v-if="error">{{ error }}</div>
</div>
</template>

<script>
/* eslint-disable */
import uuidv4 from 'uuid/v4'

const extRegex = /(?:\.([^.]+))?$/
export default {
  name: 'FilePicker',
  props: {
    path: {
      type: String,
      default: function() {
        return uuidv4()
      }
    },
    defaultName: {
      type: String,
    },
    accept: {
      type: String,
      default: '*/*',
    },
    autoInvoke: {
      type: Boolean,
      default: false,
    },
    storageLevel: {
      type: String,
      default: 'protected',
    },
    metadata: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data () {
    return {
      file: null,
      s3ImagePath: '',
      photoUrl: '',
      error: '',
      fileList: [],
      progressPercentage: 0,
      progressStatus: null,
    }
  },
  mounted() {
    if (!this.path) {
      return this.setError('File path not provided.');
    }
    if (this.autoInvoke) {
      this.$refs.chooseButton.$el.click()
    }
  },
  methods: {
    upload: function() {
      this.$Amplify.Storage.put(
        this.s3ImagePath,
        this.file,
        {
          level: this.storageLevel,
          customPrefix: {
            public: 'file/'
          },
          contentType: this.file.type,
          progressCallback: (progress) => {
            this.progressPercentage = Math.round(+progress.loaded / +progress.total * 100)
          }
        },
      )
      .then((result) => {
        this.completeFileUpload(result.key)
      })
      .catch(e => this.setError(e));
    },
    chooseFile: function(file, fileList) {
      this.fileList = fileList.slice(-1)
      this.progressPercentage = 0
      this.progressStatus = null
      this.fileMeta = file
      this.file = file.raw
      const ext = extRegex.exec(file.name)[1]
      let name = this.file.name
      if (this.defaultName) {
        if (ext) {
          name = `${this.defaultName}.${ext}`
        } else {
          name = this.defaultName
        }
      }
      this.s3ImagePath = `${this.path}/${name}`;
      const that = this;
      const reader = new FileReader();
      reader.onload = (e) => {
        const target = e.target;
        const url  = target.result;
        that.photoUrl = url;
        this.upload()
      }
      reader.readAsDataURL(this.file);
    },
    completeFileUpload: function(file) {
      this.progressPercentage = 100
      this.progressStatus = 'success'
      this.file = null;
      this.s3ImageFile = null;
      // this.$refs.file_input.value = null;
      this.$emit('fileUpload', file);
    },
    setError: function(e) {
      console.log(e)
      this.progressStatus = 'exception'
      this.error = e
    }
  }
}
</script>
