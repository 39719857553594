const env = process.env.VUE_APP_BACKEND_ENV || process.env.NODE_ENV || 'development'

export const getServiceIdEndpoint = {
  production: 'https://api.floom.app/get-service-id',
  development: 'https://api-dev.floom.app/get-service-id',
}[env]

export const getServiceEndpoint = {
  production: 'https://api.floom.app/get-service',
  development: 'https://api-dev.floom.app/get-service',
}[env]

export const getSellerServicesEndpoint = {
  production: 'https://api.floom.app/get-seller-services',
  development: 'https://api-dev.floom.app/get-seller-services',
}[env]
