<template>
<!-- eslint-disable -->
<div class="container" v-show="show">
  <svg width="200" height="200" id="logo" viewBox="0 15 200 200">
  <linearGradient id="grad" x2="1" y2="1" gradientTransform="rotate(45)">
    <stop offset="0%" stop-color="#420ABA" />
    <stop offset="100%" stop-color="#C83FAB" />
  </linearGradient>
  <g transform="rotate(5), scale(0.5)">
    <path class="wing" fill="#FB8472" transform="rotate(25 170 170)" d="M100,310 A125,125 45 0 0 200 130"></path>
    <path class="wing" fill="#D86D80" transform="rotate(45 170 170)" d="M100,310 A125,125 45 0 0 200 130"></path>
    <path class="wing" fill="#AE5190" transform="rotate(65 170 170)" d="M100,310 A125,125 45 0 0 200 130"></path>
    <path class="wing" fill="#933F9B" transform="rotate(85 170 170)" d="M100,310 A125,125 45 0 0 200 130"></path>
    <path class="wing" fill="#7129A8" transform="rotate(105 170 170)" d="M100,310 A125,125 45 0 0 200 130"></path>
    <path class="wing" fill="#430BB9" transform="rotate(125 170 170)" d="M100,310 A125,125 45 0 0 200 130"></path>
    <path transform="translate(110 140)" opacity="0" fill="url(#grad)" class="body"
      d="m0.2 163.5c0.3-0.6 5.5-12.5 11.7-26.3 14.5-32.7 18.1-41 17.6-41.3 12.4-21.1 23.4-51.5 32.1-70.3 4.7-9.7 12.2-17.6 20.8-21.8 11.2-5.5 14.7-5.4 61.1 2.7 21.3 3.7 40.6 7 43 7.4 9.3 1.4 2.6 1.4-16.2 0-10.8-0.8-22.8-1.4-26.5-1.4-13.5 0-23.7 5.2-31 16-5.1 7.4-8.1 17.2-9.8 32.1-0.8 6.7-2.2 15.2-3.1 18.9-5.9 23.2-21.9 44.7-44.5 59.8-12 8-31.7 17.8-44.8 22.1-10.9 3.6-10.9 3.6-10.3 2.1z">
    </path>
    <path opacity="1" transform="scale(0.6, 0.6), translate(140, 240), rotate(-5)"
      d="M0,371.167 C0,371.167 33.778,289.2084 36.824,272.675 C53.3517,182.963 143.519,63.794 162.717,25.632 C166.772,17.573 209.404,-30.025 266.233,29.532 C266.386,29.693 266.941,30.199 268.023,30.244 C268.275,30.255 268.975,30.912 270.062,30.922 C285.007,31.062 359.548,31.161 359.548,31.161 L359.424,41.002 C359.424,41.002 281.205,41.117 274.823,41.112 C272.487,41.111 268.321,43.764 267.316,44.036 C254.75,47.44 250.134,51.076 246.136,65.304 C244.071,72.655 227.502,101.214 218.591,133.041 C203.718,186.158 187.285,203.457 133.374,235.806 C123.378,241.805 106.913,247.859 97.4669,249.886 C76.3536,254.415 65.4689,260.291 55.3463,274.624 C48.1087,284.8722 42.4396,301.0617 36.6472,315.9126 C35.088,319.91 10.0101,375.635 10.0101,375.635 L0,371.167 Z"
      fill="url(#grad)"></path>
  </g>
  </svg>
  <p class="has-text-primary is-overline is-size-6-7">Loading...</p>
</div>
<!-- eslint-enable -->
</template>

<script>
export default {
  name: 'LoaderHummingbird',
  data() {
    return {
      show: false,
    }
  },
  props: {
    delay: {
      type: Number,
      default: 1000,
    },
  },
  mounted() {
    setTimeout(() => {
      this.show = true
    }, this.delay)
  },
}
</script>


<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  // background-color: #F6F6F4
}

$duration: 2.4s;

#logo {
  margin: 0 auto;
  text-align: center;
  display: block;
  animation-duration: $duration;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: bounce;
}

.body {
  // fill: rgba(33, 33, 33, 1.0);

}

.wing {
  // fill-opacity: 0.7;
  // fill: rgba(33, 33, 33, 0.7);
  animation-duration: $duration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
/*   animation-direction: alternate; */
}

@for $i from 1 through 6 {
  .wing:nth-child(#{$i}){
    animation-name: pulse#{$i};
  }
}

$end-delay: 0;
$unit: (100 - $end-delay) / 18;
$parfade: 1;
$finalfade: 0.01;

@keyframes bounce {
  0% { transform: translateY(0px); }
  #{9 * $unit * 1%} { transform: translateY(8px); }
}

@for $j from 1 through 6 {
  @keyframes pulse#{$j} {
    0%, 100% { opacity: 0; }
    #{($j * $unit) * 1%} { opacity: 0; }
    #{(($j + 1) * $unit) * 1%} { opacity: 1; }
    #{(($j + 3) * $unit) * 1%} { opacity: $parfade; }

    @if($j != 7) {
      #{((8 * $unit) + ((5 - $j) * $unit) + $unit) * 1% } {
        opacity: #{$parfade - ((6 - $j)/5 * ($parfade - $finalfade))};
      }
    }

    #{(((6 - $j) + 9) * $unit) * 1%} { opacity: 1; }
    #{(((6 - $j) + 10) * $unit) * 1%} { opacity: 0.6; }
    #{(((6 - $j) + 13) * $unit) * 1%} { opacity: 0; }
  }
}
</style>
