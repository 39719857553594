export const getService = `query GetService($id: ID!) {
  getService(id: $id) {
    id
    title
    previews {
      type
      value
      description
    }
    amount
    summary
    description
    seoTitle
    seoDescription
    seoImage
    input {
      type
      key
      label
      placeholder
      required
      description
      defaultValue
      accept
      options {
        value
        label
      }
      selectMultiple
      selectNone
      selectCreate
    }
    archived
    apiType
    owner
    draft
    plans {
      items {
        id
        stripeId
        name
        description
        allowance
        owner
      }
      nextToken
    }
    stripeId
  }
}
`

export const listSubs = `query ListSubscriptions(
  $filter: ModelSubscriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubscriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      stripeId
      owner
      createdAt
      plan {
        id
        stripeId
        owner
        allowance
        name
        description
        service {
          id
          title
          summary
          description
          archived
          owner
          stripeId
        }
      }
    }
  }
}
`

export const reqsByOwner = `query ReqsByOwner(
  $owner: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRequisitionFilterInput
  $limit: Int
  $nextToken: String
) {
  reqsByOwner(
    owner: $owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      service {
        id
        amount
        owner
        title
      }
      amount
      createdAt
      status {
        value
        date
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      chargeId
      receiptUrl
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    nextToken
  }
}
`
