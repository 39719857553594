<template>
  <div>
    <el-form :model="userInput" :rules="rules">
      <div class="columns is-multiline">
        <div
          v-for="input in service.input"
          :key="input.key"
          class="column is-12 has-no-padding-vertical has-no-margin-vertical"
        >
          <p
            :class="{ 'has-text-white': darkMode, 'has-text-dark': !darkMode }"
            class="is-size-6-7 has-text-weight-bold has-margin-top"
          >
            {{ input.label }}
            <span v-if="!input.required">(optional)</span>
            <el-popover placement="top-start" width="300" trigger="hover">
              <p>{{ input.description }}</p>
              <el-button
                :class="{ 'has-text-light': darkMode, 'has-text-grey': !darkMode }"
                class="has-no-padding-left"
                style="display: inline"
                type="text"
                icon="el-icon-info"
                circle
                slot="reference"
                size="mini"
              ></el-button>
            </el-popover>
          </p>

          <el-form-item class="has-no-margin-bottom" v-if="input.type === INPUT_TYPE.TEXT" :prop="input.key">
            <el-input
              type="text"
              :required="input.required"
              v-model="userInput[input.key]"
              :placeholder="input.placeholder"
            ></el-input>
          </el-form-item>
          <el-form-item class="has-no-margin-bottom" v-if="input.type === INPUT_TYPE.COLOR" :prop="input.key">
            <el-color-picker :required="input.required" v-model="userInput[input.key]"></el-color-picker>
          </el-form-item>
          <el-form-item class="has-no-margin-bottom" v-if="input.type === INPUT_TYPE.SELECT" :prop="input.key">
            <el-select
              v-model="userInput[input.key]"
              :clearable="input.selectNone"
              :allow-create="input.selectCreate"
              :multiple="input.selectMultiple"
              :placeholder="input.placeholder"
              filterable
            >
              <el-option
                v-for="option in input.options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="has-no-margin-bottom" v-if="input.type === INPUT_TYPE.FILE" :prop="input.key">
            <el-input
              :required="input.required"
              v-if="!metaInput[input.key].uploadFile"
              v-model="userInput[input.key]"
              :placeholder="input.placeholder"
            ></el-input>
            <el-button
              type="text"
              size="small"
              @click="metaInput[input.key].uploadFile = true"
              v-if="!metaInput[input.key].uploadFile && user"
              >Or Upload File</el-button
            >
            <el-button type="text" size="small" @click="requestAuth" v-if="!metaInput[input.key].uploadFile && !user"
              >Or Upload File</el-button
            >
            <file-picker
              v-if="metaInput[input.key].uploadFile"
              storage-level="public"
              :accept="input.accept || '*/*'"
              :autoInvoke="true"
              :defaultName="input.key"
              :metadata="{ serviceId: service.id, serviceOwner: service.owner, owner: user.username }"
              v-on:fileUpload="userInput[input.key] = getPublicUrl($event)"
            ></file-picker>
            <el-button
              type="text"
              size="small"
              @click="metaInput[input.key].uploadFile = false"
              v-if="metaInput[input.key].uploadFile"
              >Or Input URL</el-button
            >
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { INPUT_TYPE } from '@/graph-constants'
import { publicUrl } from '@/api/storage'
import FilePicker from '@/components/FilePicker.vue'

export default {
  name: 'ServiceInputForm',
  props: {
    service: Object,
    darkMode: { type: Boolean, default: false },
  },
  components: {
    FilePicker,
  },
  data() {
    return {
      INPUT_TYPE,
      userInput: null,
      metaInput: null,
      rules: null,
      user: this.$store.state.user,
    }
  },
  created() {
    this.metaInput = (this.service.input || []).reduce((acc, input) => {
      acc[input.key] = {}
      if (input.type === this.INPUT_TYPE.FILE) {
        acc[input.key].uploadFile = false
      }
      return acc
    }, {})
    this.userInput = (this.service.input || []).reduce((acc, input) => {
      if (input.type === INPUT_TYPE.SELECT && !input.selectNone) {
        if (input.selectMultiple) {
          acc[input.key] = [input.options[0].value]
        } else {
          acc[input.key] = input.options[0].value
        }
      } else {
        acc[input.key] = input.defaultValue || ''
      }
      return acc
    }, {})
    this.rules = (this.service.input || []).reduce((rules, input) => {
      if (input.required === true) {
        rules[input.key] = { required: true, trigger: 'blur', message: `${input.label} is required` }
      }
      return rules
    }, {})
  },
  watch: {
    userInput: {
      deep: true,
      handler(newValue) {
        this.$emit('update', newValue)
      },
    },
  },
  methods: {
    getPublicUrl(key) {
      return `${publicUrl}/${key}`
    },
    requestAuth() {
      this.$emit('requestAuth')
    },
  },
}
</script>
